import React from "react";

interface ServiceDetailsProps {
  status: "Pendente" | "Concluído";
}

const ServiceDetails: React.FC<ServiceDetailsProps> = ({ status }) => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col w-full text-neutral-700">
        <div
          className={`gap-1 self-start px-2 py-0.5 text-xs tracking-wide whitespace-nowrap rounded-[100px] 
            text-white ${
              status === "Concluído" ? "bg-green-500" : "bg-yellow-500"
            }`}
        >
          {status}
        </div>
        <h2 className="text-3xl leading-9">
          <span className="text-sm leading-5 text-neutral-700">
            Detalhes do{" "}
          </span>
          <span className="font-medium text-sm leading-6 text-neutral-700">
            Serviço:{" "}
          </span>
        </h2>
      </div>
      <div
        className={`mt-2 w-full border border-solid min-h-[1px] ${
          status === "Concluído" ? "border-green-500" : "border-zinc-300"
        }`}
      />
    </div>
  );
};

export default ServiceDetails;
