"use client";

import { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { CalendarIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  LinkIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import { useAddOrcamentosModal } from "@/store/use-add-orcamentos";
import { InputContactsList } from "../inputs/contacts-list";
import { Calendar } from "../ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { cn, formatPrice } from "@/lib/utils";
import { Button } from "../ui/button";
import ServiceListSelect from "../inputs/service-list";
import { format } from "date-fns";
import { useApiMutation } from "@/hooks/use-api-mutation";
import { api } from "@/convex/_generated/api";
import { useOrganization } from "@clerk/nextjs";
import { toast } from "sonner";
import { useAddServiceModal } from "@/store/use-add-service";
import { useAddUserModal } from "@/store/use-add-user-modal";

export default function CreateOrcamentos() {
  const { organization } = useOrganization();
  const { mutate: createOrcamento } = useApiMutation(
    api.orcamentos.create.mutate
  );
  const [date, setDate] = useState();
  const { isOpen, onClose, initialValues } = useAddOrcamentosModal();
  const { onOpen: onOpenAddService } = useAddServiceModal();
  const { onOpen: onOpenAddContact } = useAddUserModal();
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [description, setDescription] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [isChecked, setIsChecked] = useState(false);
  const [discount, setDiscount] = useState<any>(0);

  const handleChangeServices = (newSelected) => {
    setSelectedOptions(newSelected);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const totalRevenueCurrent = selectedOptions?.reduce((total, sale) => {
    return total + parseFloat(sale.price);
  }, 0);

  const discountAmount = (totalRevenueCurrent * discount) / 100;
  const totalRevenueWithDiscount = totalRevenueCurrent - discountAmount;

  const handleCreateOrcamento = async (e) => {
    e.preventDefault();
    const data = {
      description,
      date,
      client: selectedClient,
      services: selectedOptions,
      discount,
    };

    createOrcamento({
      orgId: organization?.id as string,
      description,
      date: date ? new Date(date).toISOString() : (null as any),
      clientId: selectedClient,
      servicesId: selectedOptions.map((service) => service._id),
      discount,
    }).then(() => {
      onClose();
      toast.success("Orçamento criado com sucesso");
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-500"
            >
              <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div className="h-0 flex-1 overflow-y-auto">
                  <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                    <div className="flex items-center justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-white">
                        Novo Orçamento
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => onClose()}
                          className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                    <div className="mt-1">
                      <p className="text-sm text-indigo-300">
                        Crie um novo orçamento para o cliente.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col justify-between">
                    <div className="divide-y divide-gray-200 px-4 sm:px-6">
                      <div className="space-y-6 pb-5 pt-6">
                        <div>
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Validade do Orçamento
                          </label>
                          <div className="mt-2">
                            <Popover>
                              <PopoverTrigger className="w-full">
                                <Button
                                  type="button"
                                  variant={"outline"}
                                  className={cn(
                                    "w-full justify-start text-left font-normal"
                                  )}
                                >
                                  <CalendarIcon className="mr-2 h-4 w-4" />

                                  {date ? (
                                    format(date, "PPP")
                                  ) : (
                                    <span>Selecione uma data</span>
                                  )}
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent className="w-auto p-0">
                                <Calendar
                                  mode="single"
                                  selected={date}
                                  onSelect={(newDate) => {
                                    setDate(newDate as any);
                                  }}
                                />
                              </PopoverContent>
                            </Popover>
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="description"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Descrição
                          </label>
                          <div className="mt-2">
                            <textarea
                              id="description"
                              name="description"
                              rows={4}
                              onChange={(e) => setDescription(e.target.value)}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div>
                          <h3 className="text-sm font-medium leading-6 text-gray-900">
                            Cliente
                          </h3>
                          <div className="mt-2">
                            <div className="flex space-x-2 items-center">
                              <InputContactsList
                                onClientSelect={(clientId) => {
                                  setSelectedClient(clientId);
                                }}
                              />
                              <button
                                // @ts-ignore
                                onClick={onOpenAddContact}
                                type="button"
                                className="relative inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-dashed border-gray-200 bg-white text-gray-400 hover:border-gray-300 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              >
                                <span className="absolute -inset-2" />
                                <span className="sr-only">Add team member</span>
                                <PlusIcon
                                  aria-hidden="true"
                                  className="h-5 w-5"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h3 className="text-sm font-medium leading-6 text-gray-900">
                            Serviços
                          </h3>
                          <div className="mt-2">
                            <div className="flex space-x-2 items-center">
                              <ServiceListSelect
                                onChange={handleChangeServices}
                                selected={selectedOptions}
                                className="w-full"
                              />
                              <button
                                type="button"
                                // @ts-ignore
                                onClick={() =>
                                  onOpenAddService(organization?.id as string)
                                }
                                className="relative inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-dashed border-gray-200 bg-white text-gray-400 hover:border-gray-300 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              >
                                <span className="absolute -inset-2" />
                                <span className="sr-only">Add team member</span>
                                <PlusIcon
                                  aria-hidden="true"
                                  className="h-5 w-5"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <fieldset>
                          <div className="space-y-5">
                            <div className="relative flex items-start">
                              <div className="flex h-6 items-center">
                                <input
                                  id="comments"
                                  name="comments"
                                  type="checkbox"
                                  aria-describedby="comments-description"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  checked={isChecked}
                                  onChange={handleCheckboxChange}
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6 flex-col w-full">
                                <label
                                  htmlFor="comments"
                                  className="font-medium text-gray-900"
                                >
                                  Adicionar desconto
                                </label>
                                {isChecked && (
                                  <div className="relative mt-2 rounded-md shadow-sm">
                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                      <span className="text-gray-500 sm:text-sm">
                                        %
                                      </span>
                                    </div>
                                    <input
                                      id="price"
                                      name="price"
                                      type="number"
                                      onChange={(e) =>
                                        setDiscount(e.target.value)
                                      }
                                      placeholder="5"
                                      aria-describedby="price-currency"
                                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-[40px]"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
                          <div className="flex justify-between">
                            <dt>Subtotal</dt>
                            <dd className="text-gray-900">
                              {formatPrice(totalRevenueCurrent)}
                            </dd>
                          </div>

                          {isChecked && (
                            <div className="flex justify-between">
                              <dt>Desconto</dt>
                              <dd className="text-gray-900">
                                - {formatPrice(discountAmount)} ({discount}%)
                              </dd>
                            </div>
                          )}

                          <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                            <dt className="text-base">Total</dt>
                            <dd className="text-base">
                              {formatPrice(totalRevenueWithDiscount)}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-shrink-0 justify-end px-4 py-4">
                  <button
                    type="button"
                    onClick={() => onClose()}
                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={handleCreateOrcamento}
                    className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Save
                  </button>
                </div>
              </form>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
