import { create } from "zustand";

const defaultValues = {
  date: "",
  title: "",
  services: [] as string[],
  note: "",
  price: 0,
  orgId: "",
  clientId: "",
  appointmentId: "",
};

interface IAddSaleModal {
  isOpen: boolean;
  initialValues: typeof defaultValues;
  onOpen: (
    date?: string,
    title?: string,
    services?: string[],
    note?: string,
    orgId?: string,
    clientId?: string,
    appointmentId?: string
  ) => void;
  onClose: () => void;
}

export const useAddAppointmentModal = create<IAddSaleModal>((set) => ({
  isOpen: false,
  initialValues: defaultValues,
  onOpen: (
    date = "",
    title = "",
    services = [],
    note = "",
    orgId = "",
    clientId = "",
    appointmentId = ""
  ) =>
    set({
      isOpen: true,
      initialValues: {
        ...defaultValues,
        date,
        title,
        services,
        note,
        orgId,
        clientId,
        appointmentId,
      },
    }),
  onClose: () => set({ isOpen: false, initialValues: defaultValues }),
}));
