"use client";

import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { useAddSaleModal } from "@/store/use-add-sale-modal";
import { Button } from "../ui/button";

import { ScrollArea } from "../ui/scroll-area";
import SaleDetails from "../sale-details";

export const AddSaleModal = () => {
  const { isOpen, initialValues, onOpen, onClose } = useAddSaleModal();

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Adicionar Venda</SheetTitle>
          <SheetDescription></SheetDescription>
        </SheetHeader>

        <ScrollArea className="h-full">
          <SaleDetails
            services={initialValues.services}
            clientId={initialValues.clientId}
            appointmentId={initialValues.appointmentId}
            paymentMethod={"credit"}
          />
        </ScrollArea>
        <SheetFooter>
          <SheetClose asChild>
            <Button type="submit">Save changes</Button>
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};
