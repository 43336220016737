import { create } from "zustand";

const defaultValues = {
  category: "",
};

interface IAddExpensesModal {
  isOpen: boolean;
  initialValues: typeof defaultValues;
  onOpen: () => void;
  onClose: () => void;
}

export const useAddExpensesModal = create<IAddExpensesModal>((set) => ({
  isOpen: false,
  initialValues: defaultValues,
  onOpen: () =>
    set({
      isOpen: true,
      initialValues: {
        ...defaultValues,
      },
    }),
  onClose: () => set({ isOpen: false, initialValues: defaultValues }),
}));
