import { create } from "zustand";

const defaultValues = {
  clientId: "",
  services: [] as string[],
  appointmentId: "",
  price: 0,
};

interface IAddSaleModal {
  isOpen: boolean;
  initialValues: typeof defaultValues;
  onOpen: (clientId: string, services: string[], appointmentId: string) => void;
  onClose: () => void;
}

export const useAddSaleModal = create<IAddSaleModal>((set) => ({
  isOpen: false,
  initialValues: defaultValues,
  onOpen: (clientId, services, appointmentId) =>
    set({
      isOpen: true,
      initialValues: {
        ...defaultValues,
        clientId,
        services,
        appointmentId,
      },
    }),
  onClose: () => set({ isOpen: false, initialValues: defaultValues }),
}));
