import { create } from "zustand";

const defaultValues = {
  id: "",
  name: "",
  price: 0,
  description: "",
  postSale: "",
};

interface IAddServiceModal {
  isOpen: boolean;
  initialValues: typeof defaultValues;
  onOpen: (
    id?: string,
    name?: string,
    price?: number,
    description?: string,
    postSale?: string
  ) => void;
  onClose: () => void;
}

export const useAddServiceModal = create<IAddServiceModal>((set) => ({
  isOpen: false,
  initialValues: defaultValues,
  onOpen: (id = "", name = "", price = 0, description = "", postSale = "") =>
    set({
      isOpen: true,
      initialValues: {
        ...defaultValues,
        id,
        name,
        price,
        description,
        postSale,
      },
    }),
  onClose: () => set({ isOpen: false, initialValues: defaultValues }),
}));
