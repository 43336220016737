"use client";

import { AddappointmentModal } from "@/components/modals/add-appointment";
import { ExpensesModal } from "@/components/modals/add-expenses";
import CreateOrcamentos from "@/components/modals/add-orcamentos";
import { AddSaleModal } from "@/components/modals/add-sale-modal";
import { CreateServiceForm } from "@/components/modals/create-service-modal";
import PaymentModalSubs from "@/components/modals/modal-payment";
import { RenameModal } from "@/components/modals/rename-modal";
import { ShowAppointmentModal } from "@/components/modals/ShowAppointment/show-appointment";
import { UserAddModal } from "@/components/modals/user-add-modal";
import { useOrganization, useUser } from "@clerk/nextjs";
import Script from "next/script";
import posthog from "posthog-js";
import { useEffect, useState } from "react";

export const ModalProvider = () => {
  const [isMounted, setIsMounted] = useState(false);
  const { organization } = useOrganization({});
  const user = useUser();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    const win = window as any;

    if (typeof window !== "undefined") {
      posthog.identify(user.user?.id, {
        email: user.user?.primaryEmailAddress?.emailAddress,
        name: user.user?.firstName,
      });

      posthog.capture("$set", {
        $set: { name: user.user?.firstName },
        $set_once: { email: user.user?.primaryEmailAddress?.emailAddress },
      });

      posthog.capture("$set", {
        $set_once: { orgId: organization?.id },
      });

      posthog.setPersonPropertiesForFlags({ orgId: organization?.id });
    }

    if (typeof win.Featurebase !== "function") {
      win.Featurebase = function () {
        // eslint-disable-next-line prefer-rest-params
        (win.Featurebase.q = win.Featurebase.q || []).push(arguments);
      };
    }
    win.Featurebase("initialize_changelog_widget", {
      organization: "soumotors", // Replace this with your featurebase organization name
      placement: "right", // Choose between right, left, top, bottom placement (Optional if fullscreenPopup is enabled)

      alwaysShow: true,
      theme: "light", // Choose between dark or light theme
      fullscreenPopup: true, // Optional - Open a fullscreen announcement of the new feature to the user
      locale: "pt", // Change the language, view all available languages from https://help.featurebase.app/en/articles/8879098-using-featurebase-in-my-language
      usersName: organization?.name, // Optional - Show the users name in the welcome message for the fullscreen popup
    });
  }, []);

  useEffect(() => {
    const win = window as any;

    if (typeof win.Featurebase !== "function") {
      win.Featurebase = function () {
        // eslint-disable-next-line prefer-rest-params
        (win.Featurebase.q = win.Featurebase.q || []).push(arguments);
      };
    }
    win.Featurebase("initialize_feedback_widget", {
      organization: "soumotors",
      theme: "light",
      placement: "right",
      email: "contato@soumotors.com",
      defaultBoard: "yourboardname",
      locale: "pt",
      metadata: null,
    });
  }, []);

  if (!isMounted) return null;

  return (
    <>
      <Script src="https://do.featurebase.app/js/sdk.js" id="featurebase-sdk" />
      <ShowAppointmentModal />
      <PaymentModalSubs />
      <RenameModal />
      <UserAddModal />
      <AddSaleModal />
      <AddappointmentModal />
      <CreateServiceForm />
      <ExpensesModal />
      <CreateOrcamentos />
    </>
  );
};
