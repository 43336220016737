import React from "react";

interface ServiceItemProps {
  value: string;
  description: string;
}

const ServiceItem: React.FC<ServiceItemProps> = ({ value, description }) => {
  return (
    <div
      className="flex relative flex-col items-start px-4 py-2 mt-4 w-full bg-neutral-100"
      style={{
        borderRadius: "10px",
      }}
    >
      <div className="flex z-0 flex-col whitespace-nowrap">
        <label className="text-sm  text-neutral-500">Valor</label>
        <div className="text-xl font-medium leading-tight text-neutral-700">
          {value}
        </div>
      </div>
      <div className="z-0 self-stretch mt-2 text-sm  text-zinc-900">
        {description}
      </div>
      {/* <button className="flex absolute top-2.5 right-2.5 z-0 justify-center items-center text-xs font-bold leading-none uppercase whitespace-nowrap bg-red-500 rounded-sm h-[26px] min-h-[26px] text-neutral-100 tracking-[2px]">
        <div className="overflow-hidden self-stretch p-2 my-auto min-h-[26px]">
          Remover
        </div>
      </button> */}
    </div>
  );
};

export default ServiceItem;
