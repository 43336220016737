"use client";

import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { z } from "zod";

import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { InputContactsList } from "../inputs/contacts-list";
import { useAddAppointmentModal } from "@/store/use-add-appointment";
import { useHookFormMask } from "use-mask-input";
import { useForm } from "react-hook-form";
import { Switch } from "../ui/switch";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { Calendar } from "../ui/calendar";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Form,
} from "../ui/form";

import ServiceListSelect from "../inputs/service-list";
import { useApiMutation } from "@/hooks/use-api-mutation";
import { api } from "@/convex/_generated/api";
import { useOrganization } from "@clerk/nextjs";
import { toast } from "sonner";
import { Textarea } from "../ui/textarea";
import { useAddExpensesModal } from "@/store/use-add-expenses";

const appointmentFormSchema = z.object({
  orgId: z.string().optional(),
  title: z.string().min(2).max(30),
  description: z.string().optional(),
  userId: z.string().optional(),
  date: z.date().optional(),
  amount: z.string().optional(),
});

type AppointmentFormValues = z.infer<typeof appointmentFormSchema>;

// Ajuste dos valores padrão para corresponder ao esquema
const defaultValues: Partial<AppointmentFormValues> = {
  orgId: "",
  title: "",
  description: "",
  amount: "",
};

export const ExpensesModal = () => {
  const { organization } = useOrganization();
  const { mutate, pending } = useApiMutation(api.expenses.expense.default);
  const [date, setDate] = useState();
  const { isOpen, onClose, initialValues } = useAddExpensesModal();

  const form = useForm<AppointmentFormValues>({
    resolver: zodResolver(appointmentFormSchema),
    defaultValues,
    mode: "onChange",
  });

  const registerWithMask = useHookFormMask(form.register);

  const onSubmit = (data: any) => {
    mutate({
      ...data,
      orgId: organization?.id,
      date: data.date ? new Date(data.date).toISOString() : null,
    }).then(() => toast.success("Despesa criada"));
    onClose();
  };

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <Form {...form}>
        <form>
          <SheetContent>
            <SheetHeader>
              <SheetTitle>Adicionar Despesa</SheetTitle>
            </SheetHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem className="col-span-4">
                      <FormLabel>Nome</FormLabel>
                      <FormControl>
                        <Input
                          className="w-full"
                          placeholder="Cafe para clientes..."
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem className="col-span-4">
                      <FormLabel>Justificativa</FormLabel>
                      <FormControl>
                        <Textarea
                          className="w-full"
                          placeholder="Nosso cafe não pode faltar..."
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="grid grid-cols-4 items-center gap-4">
                <FormField
                  control={form.control}
                  name="date"
                  render={({ field }) => (
                    <FormItem className="col-span-4 flex flex-col justify-start">
                      <FormLabel>Data</FormLabel>
                      <FormControl>
                        <Popover>
                          <PopoverTrigger>
                            <Button
                              variant={"outline"}
                              className={cn(
                                "w-full justify-start text-left font-normal",
                                !date && "text-muted-foreground"
                              )}
                            >
                              <CalendarIcon className="mr-2 h-4 w-4" />
                              {date ? (
                                format(date, "PPP")
                              ) : (
                                <span>Selecione uma data</span>
                              )}
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0">
                            <Calendar
                              mode="single"
                              selected={date}
                              onSelect={(newDate) => {
                                setDate(newDate as any);
                                field.onChange(newDate);
                              }}
                              initialFocus
                            />
                          </PopoverContent>
                        </Popover>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="grid grid-cols-4 items-center gap-4">
                <FormField
                  control={form.control}
                  name="amount"
                  render={({ field }) => (
                    <FormItem className="col-span-4">
                      <FormLabel>Valor</FormLabel>
                      <FormControl>
                        <Input
                          className="w-full"
                          {...registerWithMask("amount", "brl-currency")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              {/* <div className="grid grid-cols-4 items-center gap-4">
                <FormField
                  control={form.control}
                  name="services"
                  render={({ field }) => (
                    <FormItem className="col-span-4">
                      <FormLabel>Services</FormLabel>
                      <ServiceListSelect
                        onChange={handleChangeServices}
                        selected={selectedOptions}
                        className="w-full"
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div> */}
            </div>

            <SheetFooter>
              <Button type="submit" onClick={form.handleSubmit(onSubmit)}>
                {/* {initialValues.services.length !== 0 ? "Editar" : "Salvar"}
                 */}
                Criar
              </Button>
            </SheetFooter>
          </SheetContent>
        </form>
      </Form>
    </Sheet>
  );
};
