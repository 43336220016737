"use client";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Copy, CreditCard, MoreVertical } from "lucide-react";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { Separator } from "@/components/ui/separator";
import { Button } from "./ui/button";
import { useQuery } from "convex/react";
import { api } from "@/convex/_generated/api";
import { useOrganization } from "@clerk/nextjs";
import { Service } from "./inputs/service-list";
import dayjs from "dayjs";
import { useApiMutation } from "@/hooks/use-api-mutation";
import { useState } from "react";
import { toast } from "sonner";
import { useAddSaleModal } from "@/store/use-add-sale-modal";

dayjs.locale("pt-br");

type SaleDetailsProps = {
  services: string[];
  clientId: string;
  paymentMethod: string;
  appointmentId: string;
};

const SaleDetails = ({
  services,
  clientId,
  paymentMethod,
  appointmentId,
}: SaleDetailsProps) => {
  const { onClose } = useAddSaleModal();
  const [paymentMethods, setPayment] = useState("");
  const { organization } = useOrganization();
  const { mutate: updateAppointment } = useApiMutation(
    api.appointment.completedAppointment
  );
  const { mutate, pending } = useApiMutation(api.sale.create);
  const allServices: Service[] | undefined = useQuery(api.services.get, {
    orgId: `${organization?.id}`,
  });

  // Aqui tem um BUG, o clientId não pode ser undefined
  const customer = useQuery(api.contacts.clientById, {
    // @ts-ignore
    clientId: clientId || "jh73qv5v4833ryw7hrtvvtymex6qqa19",
  });

  const filteredServices = allServices?.filter((service) =>
    services?.includes(service._id)
  );

  const subtotal =
    filteredServices?.reduce(
      (total, service) =>
        total + (service.price ? parseFloat(service.price) : 0),
      0
    ) ?? 0;

  const handleSavePayment = () => {
    mutate({
      customerId: clientId as any,
      services: services as any,
      paymentMethod: paymentMethod,
      amount: `${subtotal}` as any,
      saleDate: new Date().toISOString(),
      orgId: `${organization?.id}`,
    }).then(() => {
      updateAppointment({
        id: appointmentId as any,
      });
      toast.success("Venda Criada"), onClose();
    });
  };

  return (
    <Card className="overflow-hidden">
      <CardHeader className="flex flex-row items-start bg-muted/50">
        <div className="grid gap-0.5">
          <CardTitle className="group flex items-center gap-2 text-lg">
            Venda #{Math.floor(Math.random() * 1000)}
            <Button
              size="icon"
              variant="outline"
              className="h-6 w-6 opacity-0 transition-opacity group-hover:opacity-100"
            >
              <Copy className="h-3 w-3" />
              <span className="sr-only">Copy Order ID</span>
            </Button>
          </CardTitle>
          <CardDescription>
            Date: {dayjs().format("MMM DD, YYYY")}
          </CardDescription>
        </div>
        <div className="ml-auto flex items-center gap-1">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="icon" variant="outline" className="h-8 w-8">
                <MoreVertical className="h-3.5 w-3.5" />
                <span className="sr-only">More</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem>Edit</DropdownMenuItem>
              <DropdownMenuItem>Export</DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem>Trash</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </CardHeader>
      <CardContent className="p-6 text-sm">
        <div className="grid gap-3">
          <div className="font-semibold">Serviços</div>
          <ul className="grid gap-3">
            {filteredServices?.map((service) => (
              <li
                key={service._id}
                className="flex items-center justify-between"
              >
                <span className="text-muted-foreground">
                  {service.name} x <span>1</span>
                </span>
                <span>R$ {service.price}</span>
              </li>
            ))}
          </ul>
          <Separator className="my-2" />
          <ul className="grid gap-3">
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground">Subtotal</span>
              <span>R$ {subtotal.toFixed(2)}</span>
            </li>
            <li className="flex items-center justify-between font-semibold">
              <span className="text-muted-foreground">Total</span>
              <span>R$ {subtotal.toFixed(2)}</span>
            </li>
          </ul>
        </div>
        <Separator className="my-4" />
        <div className="grid gap-3">
          <div className="font-semibold">Cliente</div>
          <dl className="grid gap-3">
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Nome</dt>
              <dd>{customer?.name}</dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Email</dt>
              <dd>
                <a href="mailto:">{customer?.email}</a>
              </dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Phone</dt>
              <dd>
                <a href="tel:">{customer?.phone}</a>
              </dd>
            </div>
          </dl>
        </div>
        <Separator className="my-4" />
        <div className="grid gap-3">
          <div className="font-semibold">Forma de pagamento</div>
          <dl className="grid gap-3">
            <div className="flex items-center justify-between">
              <Select onValueChange={setPayment}>
                <SelectTrigger
                  className="outline-none"
                  id="status"
                  aria-label="Seleciona o metodo de pagamento"
                >
                  <SelectValue
                    className="outline-none"
                    placeholder="Seleciona o metodo de pagamento"
                  />
                </SelectTrigger>
                <SelectContent className="outline-none">
                  <SelectItem value="draft">Pix</SelectItem>
                  <SelectItem value="published">Credito</SelectItem>
                  <SelectItem value="archived">Debito</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </dl>
        </div>
      </CardContent>
      <CardFooter className="flex flex-row items-center border-t bg-muted/50 px-6 py-3">
        <div className="text-xs text-muted-foreground">
          Atualizado em
          <time dateTime="2023-11-23"> {dayjs().format("MMM DD, YYYY")}</time>
        </div>
        <Button
          variant="outline"
          className="ml-auto"
          onClick={handleSavePayment}
        >
          <CreditCard className="mr-2 h-4 w-4" />
          Salvar
        </Button>
      </CardFooter>
    </Card>
  );
};

export default SaleDetails;
