import React, { useEffect, useState } from "react";
import { MaskedInput } from "../inputs/MaskedInput";
import classNames from "@/utils/cls";
import { useRouter } from "next/navigation";

export const AddNewUser = ({ open, onClose, onSubmit, initialValues }) => {
  const router = useRouter();
  const [cpf, setCpf] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleDetails, setVehicleDetails] = useState({
    marca: "",
    modelo: "",
    placa: "",
    cor: "",
    type: "",
  });

  useEffect(() => {
    if (initialValues) {
      setName(initialValues.name);
      setEmail(initialValues.email);
      setCpf(initialValues.cpf);
      setTelefone(initialValues.telefone);
      setVehicleType(initialValues.vehicles[0]?.type);
      setVehicleDetails({
        marca: initialValues.vehicles[0]?.brand || "",
        modelo: initialValues.vehicles[0]?.model || "",
        placa: initialValues.vehicles[0]?.placa || "",
        cor: initialValues.vehicles[0]?.cor || "",
        type: initialValues.vehicles[0]?.type || "",
      });
    }
  }, [initialValues]);

  const handleVehicleDetailChange = (field, value) => {
    setVehicleDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handleSendData = (e) => {
    e.preventDefault();
    onSubmit({
      name,
      email,
      cpf,
      telefone,
      vehicleType,
      vehicleDetails,
    });

    router.refresh();
  };

  return (
    <div
      id="defaultModal"
      tabIndex={-1}
      aria-hidden="true"
      className={classNames(
        open
          ? "fixed inset-0 z-50 flex items-center justify-center w-full h-full"
          : "hidden"
      )}
    >
      {/* Overlay escuro */}
      <div
        onClick={onClose}
        className="fixed inset-0 bg-black opacity-50"
      ></div>

      {/* Modal */}
      <div
        className={classNames(
          open
            ? "relative p-4 w-full max-w-2xl h-full md:h-auto"
            : "hidden relative p-4 w-full max-w-2xl h-full md:h-auto"
        )}
      >
        <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5 z-10">
          <div className="flex justify-between items-center mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              {initialValues.name
                ? "Atualizar cliente"
                : "Adicionar novo cliente"}
            </h3>
            <button
              type="button"
              onClick={onClose}
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="defaultModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <form action="#">
            <div className="grid gap-4 mb-4 sm:grid-cols-2">
              <div>
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Nome
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Digite o nome completo do cliente"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  type="text"
                  name="brand"
                  id="brand"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Digite um e-mail para contato"
                />
              </div>
              <div>
                <label
                  htmlFor="price"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  CPF
                </label>

                <MaskedInput
                  name="cpf"
                  value={cpf}
                  onChange={(value) => setCpf(value)}
                  mask="cpf"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="CPF do cliente"
                />
              </div>
              <div>
                <label
                  htmlFor="price"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Telefone
                </label>
                <MaskedInput
                  name="telefone"
                  value={telefone}
                  onChange={(value) => setTelefone(value)}
                  mask="phone"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Digite um número para contato"
                />
              </div>
              {/* Start Carros */}
              <div className="sm:col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Quais veículos possui?
                </label>

                <div className="flex flex-wrap">
                  <div className="flex items-center me-4">
                    <input
                      id="red-radio"
                      type="radio"
                      value="carro"
                      name="colored-radio"
                      checked={vehicleType === "carro"}
                      onChange={(e) => setVehicleType(e.target.value)}
                      className="w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="red-radio"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Carro
                    </label>
                  </div>
                  <div className="flex items-center me-4">
                    <input
                      id="green-radio"
                      type="radio"
                      value="moto"
                      name="vehicle-type"
                      checked={vehicleType === "moto"}
                      onChange={(e) => setVehicleType(e.target.value)}
                      className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="green-radio"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Moto
                    </label>
                  </div>
                </div>
                <div className="grid gap-4 mt-2 mb-4 sm:grid-cols-2">
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Marca
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={vehicleDetails?.marca}
                      onChange={(e) =>
                        handleVehicleDetailChange("marca", e.target.value)
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="A marca do veículo"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Modelo
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={vehicleDetails?.modelo}
                      onChange={(e) =>
                        handleVehicleDetailChange("modelo", e.target.value)
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Modelo do veículo"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Placa
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={vehicleDetails?.placa}
                      onChange={(e) =>
                        handleVehicleDetailChange("placa", e.target.value)
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Placa do veículo"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Cor
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={vehicleDetails?.cor}
                      onChange={(e) =>
                        handleVehicleDetailChange("cor", e.target.value)
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Cor do veículo"
                    />
                  </div>
                </div>
              </div>

              {/* End Carros */}
            </div>
            <button
              onClick={handleSendData}
              className="text-white inline-flex items-center bg-primary hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 w-full justify-center"
            >
              <svg
                className="mr-1 -ml-1 w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              {initialValues.name ? "Atualizar" : "Adicionar"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
