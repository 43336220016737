"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useEffect, useState } from "react";
import { useApiMutation } from "@/hooks/use-api-mutation";
import { api } from "@/convex/_generated/api";
import { useShowAppointmentModal } from "@/store/use-add-show-appointment";
import ServiceDetails from "./serviceDetails";
import ClientDetails from "./client-details";
import ServiceItem from "./service-item";
import TotalSection from "./total-section";
import ActionButtons from "./action-buttons";
import { DialogBackdrop, Dialog, DialogPanel } from "@headlessui/react";
import ContactButtons from "./contact-buttons";
import OptionsAppointments from "./cancel-appointment";
import { useAddAppointmentModal } from "@/store/use-add-appointment";

const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
);

const formSchema = z.object({
  name: z.string().min(2, {
    message: "O nome deve ter pelo menos 2 caracteres",
  }),
  phone: z.string().regex(phoneRegex, "Invalid Number!"),
  cpf: z.string(),
  email: z.string().email("Invalid Email!"),
  numCars: z.string(),
});

export const ShowAppointmentModal = () => {
  const { isOpen, initialValues, onClose } = useShowAppointmentModal();
  const { onOpen: onOpenAppointment } = useAddAppointmentModal();
  const [services, setServices] = useState<any>([]);
  const { mutate } = useApiMutation(api.services.getById);

  useEffect(() => {
    const t = Object.keys(initialValues.client).length != 0;
    if (t) {
      mutate({
        orgId: "1",
        services: initialValues.services as any,
      }).then((result) => {
        setServices(result);
      });
    }
  }, [initialValues]);

  const total = services.reduce((acc, service) => {
    // @ts-ignore
    return acc + parseFloat(service.price);
  }, 0);

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-20 w-full">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95 max-w-[900px] w-full"
          >
            <main className="">
              <section className="flex overflow-hidden relative z-0 items-start px-2 mt-4 w-full rounded border border-solid border-slate-200 max-md:max-w-full">
                <div className="flex z-0 flex-col flex-1 shrink p-6 w-full basis-0 min-w-[240px] max-md:px-5 max-md:max-w-full">
                  <div className="flex flex-wrap gap-10 w-full max-md:max-w-full">
                    <div className="flex flex-col justify-between w-60">
                      <ServiceDetails
                        status={
                          initialValues.appointment.completed
                            ? "Concluído"
                            : "Pendente"
                        }
                      />
                      <ClientDetails
                        name={initialValues.client.name}
                        email={initialValues.client.email}
                        phone={initialValues.client.phone}
                      />
                      <ContactButtons
                        org={initialValues.orgSettings}
                        appointmentDate={
                          initialValues.appointment.appointmentDate
                        }
                        customer={initialValues.client}
                      />
                    </div>
                    <div
                      className="flex flex-col flex-1 shrink my-auto basis-0 min-w-[240px] max-h-[300px] overflow-auto"
                      style={{
                        margin: "0px auto",
                      }}
                    >
                      <div className="flex justify-end">
                        <OptionsAppointments
                          appointmentId={initialValues.appointmentId}
                          // @ts-ignore
                          customerId={initialValues.client._id}
                          serviceId={initialValues.services}
                          closeModal={onClose}
                          onOpen={onOpenAppointment}
                          appointmentDate={
                            initialValues.appointment.appointmentDate
                          }
                          title={initialValues.appointment.title}
                          note={initialValues.appointment.note}
                          orgId={initialValues.appointment.orgId}
                        />
                      </div>
                      <h3 className="text-xs font-bold leading-none text-indigo-700 uppercase">
                        Serviços
                      </h3>
                      {services.map((service, index) => (
                        <ServiceItem
                          key={index}
                          value={`R$ ${service.price.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}`}
                          description={service.name}
                        />
                      ))}
                    </div>
                  </div>
                  <TotalSection
                    subtotal={total.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                    total={total.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  />
                  {initialValues.appointment.completed ? null : (
                    <ActionButtons
                      appointmentId={initialValues.appointmentId}
                      // @ts-ignore
                      customerId={initialValues.client._id}
                      serviceIds={initialValues.services}
                      onClose={onClose}
                    />
                  )}
                </div>
                <div
                  className={`absolute left-0 top-2/4 z-0 shrink-0 w-0  border-solid -translate-y-2/4 border-[5px] h-[698px] translate-x-[0%] 
                  ${
                    initialValues.appointment.completed
                      ? "border-green-500"
                      : "border-yellow-500"
                  }
                  `}
                />
              </section>
            </main>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
