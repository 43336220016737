import React from "react";
import { useApiMutation } from "@/hooks/use-api-mutation";
import { api } from "@/convex/_generated/api";
import { useAction, useQuery } from "convex/react";
import { redirect, useRouter } from "next/navigation";
import { useSubscription } from "@/contexts/SubscriptionContext";
import { Modal } from "antd";

const PaymentModalSubs = () => {
  const upgrade = useAction(api.stripe.pay);
  const { isSubscribed } = useSubscription();

  const router = useRouter();

  const handleBuy = async () => {
    // const url = await upgrade({});
    // if (!url) return;
    // router.push(url);
  };

  return (
    <Modal open={false} footer={null} closeIcon={false} width={"760px"}>
      <div className="grid w-full items-start gap-10 rounded-lg border p-10 md:grid-cols-[1fr_200px]">
        <div className="grid gap-6">
          <h3 className="text-xl font-bold sm:text-2xl">
            O que está incluído no plano?
          </h3>
          <ul className="grid gap-3 text-sm text-muted-foreground sm:grid-cols-2">
            <li className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="mr-2 h-4 w-4"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>{" "}
              Agendas ilimitadas
            </li>
            <li className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="mr-2 h-4 w-4"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>{" "}
              2 Usuários
            </li>
            <li className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="mr-2 h-4 w-4"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>{" "}
              Contatos ilimitados
            </li>
            <li className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="mr-2 h-4 w-4"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>{" "}
              Painel de relatorios
            </li>
            <li className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="mr-2 h-4 w-4"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>{" "}
              Lembretes via whatsapp
            </li>
            <li className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="mr-2 h-4 w-4"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>{" "}
              Suporte 24/7
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-4 text-center">
          <div>
            <h4 className="text-3xl font-bold">R$ 55,00</h4>
            <p className="text-sm font-medium text-muted-foreground">
              Faturado Mensalmente
            </p>
          </div>
          <button
            onClick={handleBuy}
            className="inline-flex items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-primary text-primary-foreground hover:bg-primary/90 h-11 px-8 rounded-md"
          >
            Pagar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentModalSubs;
