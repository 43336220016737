"use client";

import { ClerkProvider, useAuth } from "@clerk/nextjs";
import { ConvexProviderWithClerk } from "convex/react-clerk";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import {
  Authenticated,
  ConvexReactClient,
  Unauthenticated,
} from "convex/react";
import { SubscriptionProvider } from "@/contexts/SubscriptionContext";
import { Root } from "@/components/form/input";
import { ptBR } from "@clerk/localizations";

interface ConvexClientProviderProps {
  children: React.ReactNode;
}

const convexUrl = process.env.NEXT_PUBLIC_CONVEX_URL!;
const convex = new ConvexReactClient(convexUrl);

if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST!,
    person_profiles: "identified_only",
  });
}

export const ConvexClientProvider = ({
  children,
}: ConvexClientProviderProps) => {
  return (
    <PostHogProvider client={posthog}>
      <ClerkProvider
        signInUrl="/sign-in"
        signUpUrl="/sign-up"
        localization={ptBR}
      >
        <ConvexProviderWithClerk useAuth={useAuth} client={convex}>
          <Authenticated>
            <Root>
              <SubscriptionProvider>{children}</SubscriptionProvider>
            </Root>
          </Authenticated>
          <Unauthenticated>{children}</Unauthenticated>
        </ConvexProviderWithClerk>
      </ClerkProvider>
    </PostHogProvider>
  );
};
