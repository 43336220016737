import React from "react";

interface TotalSectionProps {
  subtotal: string;
  total: string;
}

const TotalSection: React.FC<TotalSectionProps> = ({ subtotal, total }) => {
  return (
    <>
      <div className="mt-6 w-full border border-solid border-zinc-300 min-h-[1px] max-md:max-w-full" />
      <div className="flex flex-wrap gap-10 justify-between items-center mt-6 w-full whitespace-nowrap max-md:max-w-full">
        <div className="flex flex-col self-stretch my-auto">
          <label className="text-sm tracking-tight text-neutral-500">
            Subtotal
          </label>
          <div className="text-xl font-medium leading-tight text-neutral-700">
            {subtotal}
          </div>
        </div>
        <div className="flex flex-col self-stretch my-auto">
          <label className="text-sm tracking-tight text-neutral-500">
            Total
          </label>
          <div className="text-3xl font-medium leading-tight text-neutral-700">
            {total}
          </div>
        </div>
      </div>
    </>
  );
};

export default TotalSection;
