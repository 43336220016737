"use client";

import { useForm } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { toast } from "sonner";
import { Dialog } from "@radix-ui/react-dialog";
import { DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { Button } from "../ui/button";
import { useHookFormMask } from "use-mask-input";
import { useAddServiceModal } from "@/store/use-add-service";
import { Label } from "../ui/label";
import { useApiMutation } from "@/hooks/use-api-mutation";
import { api } from "@/convex/_generated/api";
import { useOrganization } from "@clerk/nextjs";
import { Textarea } from "../ui/textarea";
import { useEffect } from "react";
import { AddNewService } from "./add-new-service";

export function CreateServiceForm() {
  const { mutate, pending } = useApiMutation(api.service.create);
  const { mutate: updateService } = useApiMutation(api.service.update);
  const { organization } = useOrganization();
  const { isOpen, onClose, initialValues } = useAddServiceModal();

  function onSubmit(data: any) {
    if (initialValues.name) {
      updateService({
        // @ts-ignore
        id: initialValues.id,
        name: data.name,
        price: data.price,
        description: data.description,
        postSale: +data.postSale,
      }).then(() => toast.success("Servico Atualizado"));
      onClose();
      return;
    }
    mutate({
      orgId: organization?.id as string,
      name: data.name,
      price: data.price,
      description: data.description,
      postSale: +data.postSale,
    }).then(() => toast.success("Servico Criado"));
    onClose();
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <AddNewService
        initialValues={initialValues}
        onSubmit={onSubmit}
        open={isOpen}
        onClose={onClose}
      />
    </Dialog>
  );
}
