import type { ChangeEvent, HTMLProps } from "react";
import masks, { type MasksName } from "@nafuzi/brazilian-masks";

type MaskedInputProps = {
  mask: MasksName;
  onChange: (maskedValue: string) => void;
} & Omit<HTMLProps<HTMLInputElement>, "onChange">;

export const MaskedInput = ({
  name,
  value,
  onChange,
  mask,
  ...rest
}: MaskedInputProps) => {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const maskedValue = masks[mask](event.target.value);

    onChange(maskedValue);
  };

  return <input onChange={handleOnChange} value={value} {...rest} />;
};
