import { create } from "zustand";

const defaultValues = {
  client: {} as ClientObject,
  services: [] as string[],
  appointment: {} as any,
  appointmentId: "",
  orgSettings: {} as any,
};

type ClientObject = {
  name: string;
  email: string;
  phone: string;
};

type AppointmentObject = {
  title: string;
  note: string;
  date: string;
};

interface IShowAppointmentModal {
  isOpen: boolean;
  initialValues: typeof defaultValues;
  onOpen: (
    client: ClientObject,
    services: string[],
    appointmentId: string,
    appointment: AppointmentObject,
    orgSettings: any
  ) => void;
  onClose: () => void;
}

export const useShowAppointmentModal = create<IShowAppointmentModal>((set) => ({
  isOpen: false,
  initialValues: defaultValues,
  onOpen: (client, services, appointmentId, appointment, orgSettings) =>
    set({
      isOpen: true,
      initialValues: {
        ...defaultValues,
        client,
        services,
        appointmentId,
        appointment,
        orgSettings,
      },
    }),
  onClose: () => set({ isOpen: false }),
}));
