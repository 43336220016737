"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { FormEventHandler, useEffect, useState } from "react";
import {
  DialogContent,
  Dialog,
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from "../ui/dialog";
import { Input } from "../ui/input";
import { DialogClose } from "@radix-ui/react-dialog";
import { Button } from "../ui/button";
import { useApiMutation } from "@/hooks/use-api-mutation";
import { api } from "@/convex/_generated/api";
import { toast } from "sonner";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useAddUserModal } from "@/store/use-add-user-modal";
import { AddNewUser } from "./add-new-user";

const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
);

type UserForm = {
  name: string;
  email: string;
  cpf: string;
  telefone: string;
  vehicleType: "carro" | "moto";
  vehicleDetails: {
    type: string;
    modelo: string;
    marca: string;
    placa: string;
    cor: string;
  };
};

export const UserAddModal = () => {
  const { mutate, pending } = useApiMutation(api.contact.create);
  const { mutate: updateContact } = useApiMutation(api.contact.update);
  const { isOpen, initialValues, onClose } = useAddUserModal();

  const onSubmit = (values: UserForm) => {
    if (initialValues.id) {
      updateContact({
        // @ts-ignore
        id: initialValues.id,
        email: values.email,
        name: values.name,
        cpf: values.cpf,
        phone: values.telefone,
        vehicles: [
          {
            type: values.vehicleType || "",
            brand: values.vehicleDetails.marca,
            model: values.vehicleDetails.modelo,
            placa: values.vehicleDetails.placa,
            cor: values.vehicleDetails.cor,
          },
        ],
      }).then(() => toast.success("Usuario Atualizado"));
      onClose();
      return;
    }

    mutate({
      orgId: initialValues.orgID,
      email: values.email,
      name: values.name,
      cpf: values.cpf,
      phone: values.telefone,
      vehicles: [
        {
          type: values.vehicleType || "",
          brand: values.vehicleDetails.marca,
          model: values.vehicleDetails.modelo,
          placa: values.vehicleDetails.placa,
          cor: values.vehicleDetails.cor,
        },
      ],
    }).then(() => toast.success("Usuario Criado"));
    onClose();
  };

  return (
    <AddNewUser
      open={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      initialValues={initialValues}
    />
  );
};
