"use client";

import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { z } from "zod";

import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { InputContactsList } from "../inputs/contacts-list";
import { useAddAppointmentModal } from "@/store/use-add-appointment";
import { useForm } from "react-hook-form";
import { Switch } from "../ui/switch";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { Calendar } from "../ui/calendar";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Form,
} from "../ui/form";

import ServiceListSelect from "../inputs/service-list";
import { useApiMutation } from "@/hooks/use-api-mutation";
import { api } from "@/convex/_generated/api";
import { useOrganization } from "@clerk/nextjs";
import { toast } from "sonner";
import { Textarea } from "../ui/textarea";

const appointmentFormSchema = z.object({
  orgId: z.string().optional(),
  title: z.string().min(2).max(30),
  note: z.string().optional(),
  userId: z.string().optional(),
  appointmentDate: z.date().optional(),
  completed: z.boolean().optional(),
  canceled: z.boolean().optional(),
  services: z.array(z.string()).optional(),
  customerId: z.string().optional(),
});

type AppointmentFormValues = z.infer<typeof appointmentFormSchema>;

// Ajuste dos valores padrão para corresponder ao esquema
const defaultValues: Partial<AppointmentFormValues> = {
  orgId: "",
  title: "",
  note: "",
  userId: "",
  appointmentDate: new Date(),
  services: [],
  customerId: "",
};

export const AddappointmentModal = () => {
  const { organization } = useOrganization();
  const { mutate, pending } = useApiMutation(api.appointment.create);
  const { mutate: updateAppointment } = useApiMutation(api.appointment.update);
  const [date, setDate] = useState();
  const { isOpen, onClose, initialValues } = useAddAppointmentModal();

  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const form = useForm<AppointmentFormValues>({
    resolver: zodResolver(appointmentFormSchema),
    defaultValues,
    mode: "onChange",
  });

  const handleChangeServices = (newSelected) => {
    setSelectedOptions(newSelected);
    form.setValue(
      "services",
      newSelected.map((item) => item._id)
    );
  };

  useEffect(() => {
    if (isOpen) {
      form.reset({
        ...initialValues,
        // @ts-ignore
        services: selectedOptions.map((item) => item._id),
        // @ts-ignore
        customerId: initialValues.clientId,
      });
      // @ts-ignore
      setSelectedOptions(
        initialValues.services.map((service) => ({
          _id: service,
          name: "",
        }))
      );
      form.setValue("services", initialValues.services as any);
    }
  }, [isOpen, initialValues, form.reset]);

  useEffect(() => {
    setDate(initialValues.date as any);
    form.setValue("appointmentDate", new Date(initialValues.date) as any);
  }, [initialValues]);

  const onSubmit = (data: any) => {
    if (initialValues.services.length !== 0) {
      updateAppointment({
        ...data,
        id: initialValues.appointmentId,
        appointmentDate: data.appointmentDate
          ? new Date(data.appointmentDate).toISOString()
          : null,
      }).then(() => toast.success("Agendamento Editado"));
      onClose();
      return;
    }

    mutate({
      ...data,
      orgId: organization?.id,
      appointmentDate: data.appointmentDate
        ? new Date(data.appointmentDate).toISOString()
        : null,
    }).then(() => toast.success("Agendamento Criado"));
    onClose();
  };

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <Form {...form}>
        <form>
          <SheetContent>
            <SheetHeader>
              <SheetTitle>Adicionar Agendamento</SheetTitle>
              <SheetDescription>Adicionar um agendamento.</SheetDescription>
            </SheetHeader>
            <div className="grid gap-4 py-4">
              {/* <div className="grid grid-cols-4 items-center gap-4">
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem className="col-span-4">
                      <FormLabel>Hora</FormLabel>
                      <FormControl>
                        <Input
                          className="w-full"
                          placeholder="10:00"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div> */}

              <div className="grid grid-cols-4 items-center gap-4">
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem className="col-span-4">
                      <FormLabel>Hora</FormLabel>

                      <Input
                        type="time"
                        id="time"
                        className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        min="09:00"
                        max="18:00"
                        required
                        {...field}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="grid grid-cols-4 items-center gap-4">
                <FormField
                  control={form.control}
                  name="note"
                  render={({ field }) => (
                    <FormItem className="col-span-4">
                      <FormLabel>Observação</FormLabel>
                      <FormControl>
                        <Textarea
                          className="w-full"
                          placeholder="Não molhar motor..."
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="grid grid-cols-4 items-center gap-4">
                <FormField
                  control={form.control}
                  name="appointmentDate"
                  render={({ field }) => (
                    <FormItem className="col-span-4 flex flex-col justify-start">
                      <FormLabel>Data</FormLabel>
                      <FormControl>
                        <Popover>
                          <PopoverTrigger>
                            <Button
                              variant={"outline"}
                              className={cn(
                                "w-full justify-start text-left font-normal",
                                !date && "text-muted-foreground"
                              )}
                            >
                              <CalendarIcon className="mr-2 h-4 w-4" />
                              {date ? (
                                format(date, "PPP")
                              ) : (
                                <span>Selecione uma data</span>
                              )}
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0">
                            <Calendar
                              mode="single"
                              selected={date}
                              onSelect={(newDate) => {
                                setDate(newDate as any);
                                field.onChange(newDate);
                              }}
                              initialFocus
                            />
                          </PopoverContent>
                        </Popover>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="grid grid-cols-4 items-center gap-4">
                <FormField
                  control={form.control}
                  name="customerId"
                  render={({ field }) => (
                    <FormItem className="col-span-4">
                      <FormLabel>Cliente</FormLabel>
                      <FormControl>
                        <InputContactsList
                          defaultValue={initialValues.clientId}
                          onClientSelect={(clientId) => {
                            field.onChange(clientId);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="grid grid-cols-4 items-center gap-4">
                <FormField
                  control={form.control}
                  name="services"
                  render={({ field }) => (
                    <FormItem className="col-span-4">
                      <FormLabel>Services</FormLabel>
                      <ServiceListSelect
                        onChange={handleChangeServices}
                        selected={selectedOptions}
                        className="w-full"
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <SheetFooter>
              <Button type="submit" onClick={form.handleSubmit(onSubmit)}>
                {initialValues.services.length !== 0 ? "Editar" : "Salvar"}
              </Button>
            </SheetFooter>
          </SheetContent>
        </form>
      </Form>
    </Sheet>
  );
};
