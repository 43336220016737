"use client";

import * as React from "react";
import { cn } from "@/lib/utils";

import { Check, X, ChevronsUpDown } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Badge } from "@/components/ui/badge";
import { useQuery } from "convex/react";
import { api } from "@/convex/_generated/api";
import { useOrganization } from "@clerk/nextjs";

export type OptionType = {
  label: string;
  value: string;
};

type SelectedOptionType = {
  name: string;
  _id: string;
  price?: string;
};

interface MultiSelectProps {
  options?: OptionType[];
  selected: SelectedOptionType[];
  onChange: React.Dispatch<React.SetStateAction<SelectedOptionType[]>>;
  className?: string;
}

export type Service = {
  _id: any;
  name: string;
  price: string;
};

const ServiceListSelect = ({
  selected,
  onChange,
  className,
  ...props
}: MultiSelectProps) => {
  const [open, setOpen] = React.useState(false);
  const { organization } = useOrganization();

  const options: Service[] | undefined = useQuery(api.services.get, {
    orgId: `${organization?.id}`,
  });

  function handleUnselect(itemToRemove: SelectedOptionType) {
    onChange(selected.filter((item) => item._id !== itemToRemove._id));
  }

  return (
    <Popover open={open} onOpenChange={setOpen} {...props}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={`w-full justify-between ${
            selected.length > 1 ? "h-full" : "h-10"
          }`}
          onClick={() => setOpen(!open)}
        >
          <div className="flex gap-1 flex-wrap">
            {selected.map((item) => (
              <Badge
                variant="secondary"
                key={item._id}
                className="mr-1 mb-1"
                onClick={() => handleUnselect(item)}
              >
                {options?.find((option) => option._id === item._id)?.name ||
                  item.name}
                <button
                  className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUnselect(item);
                  }}
                >
                  <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                </button>
              </Badge>
            ))}
          </div>
          <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[335px] p-0">
        <Command className={className}>
          <CommandInput placeholder="Search ..." />
          <CommandEmpty>No item found.</CommandEmpty>
          <CommandList className="w-[335px]">
            <CommandGroup className="max-h-64 overflow-auto">
              {options?.map((option) => (
                <CommandItem
                  key={option._id}
                  className="!pointer-events-auto !opacity-100"
                  onSelect={() => {
                    const isCurrentlySelected = selected.some(
                      (item) => item._id === option._id
                    );

                    onChange(
                      isCurrentlySelected
                        ? selected.filter((item) => item._id !== option._id)
                        : [
                            ...selected,
                            {
                              name: option.name,
                              _id: option._id,
                              price: option.price,
                            },
                          ]
                    );
                    setOpen(true);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      selected.some((item) => item._id === option._id)
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                  {option.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
          <CommandSeparator />
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default ServiceListSelect;
