import { create } from "zustand";

const defaultValues = {
  orgID: "",
  name: "",
  cpf: "",
  telefone: "",
  email: "",
  numCars: "",
  vehicles: [],
  id: "",
};

interface IAddUserModal {
  isOpen: boolean;
  initialValues: typeof defaultValues;
  onOpen: (
    orgID?: string,
    name?: string,
    email?: string,
    cpf?: string,
    telefone?: string,
    numCars?: string,
    vehicles?: any,
    id?: string
  ) => void;
  onClose: () => void;
}

export const useAddUserModal = create<IAddUserModal>((set) => ({
  isOpen: false,
  initialValues: defaultValues,
  onOpen: (
    orgID = "",
    name = "",
    email = "",
    cpf = "",
    telefone = "",
    numCars = "",
    vehicles = [],
    id = ""
  ) =>
    set({
      isOpen: true,
      initialValues: {
        ...defaultValues,
        orgID,
        name,
        email,
        cpf,
        telefone,
        numCars,
        vehicles,
        id,
      },
    }),
  onClose: () => set({ isOpen: false, initialValues: defaultValues }),
}));
