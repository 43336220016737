import { api } from "@/convex/_generated/api";
import { useApiMutation } from "@/hooks/use-api-mutation";
import { useAddSaleModal } from "@/store/use-add-sale-modal";
import React from "react";
import { toast } from "sonner";

const ActionButtons: React.FC<any> = ({
  appointmentId,
  onClose,
  customerId,
  serviceIds,
}) => {
  const { onOpen: onAddSaleModalOpen } = useAddSaleModal();
  const { mutate: cancelAppointment, pending: pendingCancel } = useApiMutation(
    api.appointment.cancelAppointment
  );

  const handleCancelAppointment = (id: any) => {
    cancelAppointment({ id: appointmentId }).then(() => {
      onClose();
      toast.success("Agendamento Cancelado");
    });
  };

  const handleCreateSale = (id: any) => {
    onAddSaleModalOpen(customerId, serviceIds, appointmentId);
    onClose();
  };

  return (
    <div className="flex z-0 flex-wrap gap-4 items-start mt-4 w-full text-sm font-medium leading-none uppercase tracking-[2px] max-md:max-w-full">
      <button
        className="flex flex-1 shrink justify-center items-center whitespace-nowrap rounded-sm border border-red-500 border-solid basis-0 min-w-[240px] text-neutral-700"
        onClick={handleCancelAppointment}
      >
        <div className="overflow-hidden self-stretch px-8 py-3.5 my-auto min-h-[40px] max-md:px-5">
          Cancelar
        </div>
      </button>
      <button
        className="flex flex-1 shrink justify-center items-center bg-green-600 rounded-sm basis-0 min-w-[240px] text-neutral-100"
        onClick={handleCreateSale}
      >
        <div className="overflow-hidden self-stretch px-8 py-3.5 my-auto min-h-[40px] max-md:px-5">
          Completar serviço
        </div>
      </button>
    </div>
  );
};

export default ActionButtons;
