import React from "react";

interface ClientDetailsProps {
  name: string;
  email: string;
  phone: string;
}

const ClientDetails: React.FC<ClientDetailsProps> = ({
  name,
  email,
  phone,
}) => {
  return (
    <div className="flex flex-col w-full">
      <h2 className="text-3xl  leading-9 text-neutral-700">
        <span className="text-sm  leading-5 text-neutral-700">
          Detalhes do{" "}
        </span>
        <span className="text-sm font-medium leading-6 text-neutral-700">
          Cliente:{" "}
        </span>
      </h2>
      <div className="flex flex-col mt-4 w-full">
        <div className="flex flex-col w-full">
          <label className="text-sm  text-neutral-500">Nome</label>
          <div className="text-base font-medium  text-neutral-700">{name}</div>
        </div>
        <div className="flex flex-col mt-2 w-full whitespace-nowrap">
          <label className="text-sm  text-neutral-500">E-mail</label>
          <div className="text-base font-medium  text-neutral-700">{email}</div>
        </div>
        <div className="flex flex-col mt-2 w-full">
          <label className="text-sm  text-neutral-500">Telefone</label>
          <div className="text-base font-medium  text-neutral-700">{phone}</div>
        </div>
      </div>
    </div>
  );
};

export default ClientDetails;
